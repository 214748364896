<template>
  <div class="item d-flex flex-column">
    <div class="content">
      <div class="date">
        {{ $filters.dateFormat(item.published_at) }}
      </div>
      <div class="title">
        <router-link
          :to="{ name: 'NewsInner', params: { slug: item.slug } }"
          class="stretched-link"
        >
          {{ item.title }}
        </router-link>
      </div>
      <div class="tags" />
    </div>
    <div v-if="item.preview.image_thumb" class="image mt-auto">
      <img
        :src="
          item.preview.image_mobile
            ? item.preview.image_mobile
            : item.preview.image_thumb
        "
        width="800"
        height="533"
        loading="lazy"
        :alt="item.title"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.item {
  position: relative;
  min-height: 100%;
  padding-bottom: 65px;
}

.title {
  font-weight: normal;
}

.title a {
  color: inherit;
  text-decoration: none;
}

body.-notouch .title a:hover {
  color: #205640;
}

.date {
  margin-bottom: 8px;
  color: #9c9b9b;
}

.content {
  margin-bottom: 24px;
}

.image {
  position: relative;
  padding-bottom: 66.75%;
}

.image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-size: cover;
}
@media (max-width: 767px) {
  .item {
    padding-bottom: 40px;
  }

  .date {
    font-size: 14px;
  }

  .content {
    margin-bottom: 20px;
  }
}
</style>
