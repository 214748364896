<template>
  <Head>
    <title>Новости {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Читать новости на официальном сайте жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
    <meta property="og:title" :content="'Новости ' + $titleEnd" />
    <meta
      property="og:description"
      content="Читать новости на официальном сайте жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
  </Head>
  <main class="main">
    <div class="container">
      <Breadcrumbs
        :links="[{ name: 'Home', text: 'Главная' }, { text: 'Новости' }]"
      />
      <h1 class="page-title h2">Новости</h1>
      <div v-if="news.length > 0" ref="list" class="list">
        <div class="row">
          <div v-for="item in news" :key="item.id" class="col-lg-4 col-md-6">
            <NewsItem :item="item" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import NewsItem from "@/components/news/NewsItem.vue";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
    NewsItem,
  },
  data() {
    return {
      news: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint +
          "publications?project=9&category=1&language=ru&page=1&per_page=999"
      );

      this.news = response.data.data;

      if (!is_touch()) {
        this.$nextTick(() => {
          gsap.from(this.$refs.list.querySelectorAll(".item"), {
            autoAlpha: 0,
            y: 20,
            stagger: 0.07,
          });
        });
      }
    } catch (err) {
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
};
</script>

<style scoped>
.list {
  margin-bottom: -65px;
}
@media (max-width: 767px) {
  .list {
    margin-bottom: -40px;
  }
}
</style>
